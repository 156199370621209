import placements from '../methods/placements';

// Get rid of `auto` `auto-start` and `auto-end`
const validPlacements = placements.slice(3);


export default function clockwise(placement, counter = false) {
  const index = validPlacements.indexOf(placement);
  const arr = validPlacements
    .slice(index + 1)
    .concat(validPlacements.slice(0, index));
  return counter ? arr.reverse() : arr;
}
