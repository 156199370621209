import modifiers from '../modifiers/index';


export default {
  
  placement: 'bottom',

  
  positionFixed: false,

  
  eventsEnabled: true,

  
  removeOnDestroy: false,

  
  onCreate: () => {},

  
  onUpdate: () => {},

  
  modifiers,
};




