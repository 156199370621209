import applyStyle, { applyStyleOnLoad } from './applyStyle';
import computeStyle from './computeStyle';
import arrow from './arrow';
import flip from './flip';
import keepTogether from './keepTogether';
import offset from './offset';
import preventOverflow from './preventOverflow';
import shift from './shift';
import hide from './hide';
import inner from './inner';




export default {
  
  shift: {
    
    order: 100,
    
    enabled: true,
    
    fn: shift,
  },

  
  offset: {
    
    order: 200,
    
    enabled: true,
    
    fn: offset,
    
    offset: 0,
  },

  
  preventOverflow: {
    
    order: 300,
    
    enabled: true,
    
    fn: preventOverflow,
    
    priority: ['left', 'right', 'top', 'bottom'],
    
    padding: 5,
    
    boundariesElement: 'scrollParent',
  },

  
  keepTogether: {
    
    order: 400,
    
    enabled: true,
    
    fn: keepTogether,
  },

  
  arrow: {
    
    order: 500,
    
    enabled: true,
    
    fn: arrow,
    
    element: '[x-arrow]',
  },

  
  flip: {
    
    order: 600,
    
    enabled: true,
    
    fn: flip,
    
    behavior: 'flip',
    
    padding: 5,
    
    boundariesElement: 'viewport',
  },

  
  inner: {
    
    order: 700,
    
    enabled: false,
    
    fn: inner,
  },

  
  hide: {
    
    order: 800,
    
    enabled: true,
    
    fn: hide,
  },

  
  computeStyle: {
    
    order: 850,
    
    enabled: true,
    
    fn: computeStyle,
    
    gpuAcceleration: true,
    
    x: 'bottom',
    
    y: 'right',
  },

  
  applyStyle: {
    
    order: 900,
    
    enabled: true,
    
    fn: applyStyle,
    
    onLoad: applyStyleOnLoad,
    
    gpuAcceleration: undefined,
  },
};


